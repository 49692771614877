import * as React from 'react';

// @ts-ignore
import CaptisLogo from '../../assets/images/lambda-captis.svg';

// @ts-ignore
import BestSeller from '../../assets/images/bestSeller.png';

// @ts-ignore
import OnPremise from '../../assets/images/onPremise.svg';

// @ts-ignore
import NonProfit from '../../assets/images/nonProfit.svg';
import {MeldenSieSichLink} from "./links";

function AngebotHeader(props: { name: string }) {
    return (
        <div className="angebot-header">
            <img src={CaptisLogo} style={{height: "70px", width: "70px"}}/>
            <h3 className="angebot-header-text">{props.name}</h3>
        </div>
    );
}

const smallFontSize: React.CSSProperties = {
    fontSize: '0.8rem'
}

export default function Preise() {
    return (
        <section className="row mt-7 pt-5 pb-5">
            <div className="col-md-12">
                <h2 className="fw-bold mt-5 mb-5">Preise</h2>
                <div className="row angebot-Container mt-5">
                    <div className="col-lg-4 mt-3">
                        <div className="shadow-lg bg-white p-3 angebot">
                            <AngebotHeader name={"basic"}/>
                            <div className="text-center" style={{marginTop: "20px"}}>
                                <p className="angebot-price">39,00 € netto
                                </p>
                                <div style={{fontSize: "0.8em"}}>(46,41 € brutto)</div>
                                <p className="text-center angebot-text">pro Monat</p>
                            </div>
                            <div className="text-center" style={{marginTop: "1em"}}>
                                <p className="angebot-price">0,10 € netto
                                </p>
                                <div style={{fontSize: "0.8em"}}>(0,12 € brutto)</div>
                                <p className="text-center angebot-text">pro abgeschlossener Erfassung</p>
                            </div>
                            <ul className="pl-3 mt-5" style={{fontSize: "1.0rem", listStyle: "none"}}>
                                <li className="angebot-list-text mb-2"><i className="me-2 fas fa-user"></i>3 Benutzer
                                </li>
                                <li className="angebot-list-text mb-2"><i className="me-2 fas fa-paperclip"></i>5
                                    Formulare
                                </li>
                                <li className="angebot-list-text mb-2"><i className="me-2 fas fa-clock"></i>30 Tage
                                    Vorhaltezeit für
                                    Erfassungen/Dateien
                                </li>
                                <li className="angebot-list-text mb-2"><i className="me-2 fas fa-save"></i> 10GB
                                    maximaler
                                    Speicherplatz
                                </li>
                                <li className="angebot-list-text mb-2"><i className="me-2 fas fa-envelope"></i>Support
                                    per
                                    E-Mail (24 Std. Reaktionszeit)
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-3">
                        <div className="shadow-lg bg-white p-3 position-relative angebot">
                            <img src={BestSeller} className="angebot-Banner"/>
                            <AngebotHeader name={"premium"}/>
                            <div className="text-center" style={{marginTop: "20px"}}>
                                <p className="angebot-price">99,00 € netto
                                </p>
                                <div style={smallFontSize}>(117,81 € brutto)</div>
                                <p className="text-center angebot-text">pro Monat</p>
                            </div>
                            <div className="text-center" style={{marginTop: "1em"}}>
                                <p className="angebot-price">0,07 € netto
                                </p>
                                <div style={smallFontSize}>(0,083 € brutto)</div>
                                <p className="text-center angebot-text">pro abgeschlossener Erfassung</p>
                            </div>
                            <ul className="pl-3 mt-5" style={{fontSize: "1.0rem", listStyle: "none"}}>
                                <li className="angebot-list-text mb-2"><i
                                    className="me-2 fas fa-user"></i>unbegrenzte Benutzer
                                </li>
                                <li className="angebot-list-text mb-2"><i className="me-2 fas fa-paperclip"></i>unbegrenzte
                                    Formulare
                                </li>
                                <li className="angebot-list-text mb-2"><i className="me-2 fas fa-clock"></i>90 Tage
                                    Vorhaltezeit für
                                    Erfassungen/Dateien
                                </li>
                                <li className="angebot-list-text mb-2"><i className="me-2 fas fa-save"></i>50GB
                                    maximaler
                                    Speicherplatz
                                </li>
                                <li className="angebot-list-text mb-2"><i className="me-2 fas fa-envelope"></i>Support
                                    per
                                    E-Mail (24 Std. Reaktionszeit)
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-3">
                        <div className="bg-white shadow-lg p-3 angebot">
                            <AngebotHeader name={"unlimited"}/>
                            <div className="text-center" style={{marginTop: "20px"}}>
                                <p className="angebot-price">199,00 € netto
                                </p>
                                <div style={smallFontSize}>(236,81 € brutto)</div>
                                <p className="text-center angebot-text">pro Monat</p>
                            </div>
                            <div className="text-center" style={{marginTop: "1em"}}>
                                <p className="angebot-price">
                                    Erfassungen<br/>kostenlos
                                </p>
                            </div>
                            <ul className="pl-3 mt-5" style={{fontSize: "1.0rem", listStyle: "none"}}>
                                <li className="angebot-list-text mb-2"><i className="me-2 fas fa-user"></i>unbegrenzte
                                    Benutzer
                                </li>
                                <li className="angebot-list-text mb-2"><i
                                    className="me-2 fas fa-paperclip"></i>unbegrenzte
                                    Formulare
                                </li>
                                <li className="angebot-list-text mb-2"><i className="me-2 fas fa-clock"></i>90 Tage
                                    Vorhaltezeit
                                    für Erfassungen/Dateien
                                </li>
                                <li className="angebot-list-text mb-2"><i className="me-2 fas fa-save"></i>50GB max.
                                    Speicherplatz
                                </li>
                                <li className="angebot-list-text mb-2 d-flex"><i
                                    className="mt-1 me-2 fas fa-envelope"></i> Support
                                    per E-Mail (12 Std. Reaktionszeit) + 5 Rückrufe/Monat (12 Std. Reaktionszeit)
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-6 w-100">
                <div className="col-md-6 offset-md-3" style={{fontSize: "0.9rem"}}>
                    <em>
                        Alle Angebote haben eine Mindestvertragslaufzeit von 12 Monaten. Die
                        Kündigung erfolgt 30 Tage zum Laufzeitende mit anschließender monatlicher
                        Verlängerung. Die Bezahlung erfolgt im Voraus.
                    </em>
                </div>
            </div>
            <div className="row mt-6 w-100">
                <div className="col-md-6 d-flex justify-content-center" style={{lineHeight: "1.5"}}>
                    <div className="align-self-center" style={{maxWidth: "400px"}}>
                        <div className="vorteil-header text-center">
                            <img src={NonProfit} style={{height: "70px"}}/>
                            <h3 className="mt-3 text-center" style={{marginBottom: "0px"}}>Non-Profit Organisation?</h3>
                        </div>
                        <p className="text-center mt-3">
                            Sie sind ein Sportverein oder eine Hilfsorganisation?
                            Gerne prüfen wir eine kostenlose Nutzung für Sie.
                        </p>
                        <p className="text-center">
                            <MeldenSieSichLink />
                        </p>
                    </div>
                </div>

                <div className="col-md-6 d-flex justify-content-center" style={{lineHeight: "1.5"}}>
                    <div className="align-self-center" style={{maxWidth: "400px"}}>
                        <div className="vorteil-header text-center">
                            <img src={OnPremise} style={{height: "70px"}}/>
                            <h3 className="mt-3 text-center" style={{marginBottom: "0px"}}>On-Premise?</h3>
                        </div>
                        <p className="text-center mt-3">
                            Sie möchten captis auf Ihrem eigenen Server betreiben? Gerne entwickeln wir
                            ein passendes Angebot für Sie.
                        </p>
                        <p className="text-center">
                            <MeldenSieSichLink />
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}