import * as React from "react";

export function KontaktLink() {
    return (
        <a href="https://capt.is/formular/S8Fp5F5DcPB1VuZxMVsX4JI0L6SBLp" target="_blank" className="text-underline link fw-bold">
            Direkten Kontakt aufnehmen
            <i className="ms-1 text-muted fas fa-mail-bulk"></i>
        </a>
    );
}

export function MeldenSieSichLink() {
    return (
        <a className="link text-underline" href="https://capt.is/formular/S8Fp5F5DcPB1VuZxMVsX4JI0L6SBLp">
            Melden Sie sich!
            <i className="text-muted ms-1 fas fa-arrow-right"></i>
        </a>
);
}

export function DemoLink() {
    return (
        <a href="https://capt.is/registrierung" className="btn btn-primary">
            30 Tage kostenlos testen
            <i className="ms-1 fas fa-arrow-right"></i>
        </a>
    )
}
